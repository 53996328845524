import CustomModal from '../../common/CustomModal';
import TickIcon from '../../../css/images/tick.png';
import DiamondIcon from '../../../css/images/diamond.svg';

import '../../../css/scss/login-modals.scss';
import '../../../css/scss/common-modal.scss';
import { Link } from 'react-router-dom';

export const ConfirmationModal = ({
  setShowModal,
  showModal,
  planDetails,
  signupResponse,
}) => {
  console.log('signupResponse', signupResponse);
  const renderBody = () => (
    <div className="modal-body">
      <div className="success-ico">
        <img src={TickIcon} alt="tick" />
      </div>
      <h2 className="modal-title">Payment Successful</h2>
      <p className="modal-desc">
        {/* Lorem Ipsum is simply dummy text of the printing and <br /> typesetting
        industry. */}
        User Created Successfully
      </p>
      <div className="plan-head">
        <div className="plan-titles">
          <div className="txt">
            <h3 className="service">
              <span className="diamond">
                <img src={planDetails.icon} alt="diamond" />
              </span>
              <span>{planDetails.name}</span>
            </h3>
            <p className="descriptn">{planDetails.description}</p>
          </div>
          <div className="rate">
            $ {planDetails.amount}/{planDetails.period}
          </div>
        </div>
      </div>
    </div>
  );
  const renderFooter = () => (
    <div className="modal-footer">
      <div className="footer-table">
        <table className="table">
          <tbody>
            <tr>
              <td className="transctn">Transaction Date</td>
              <td className="text-end">
                <span className="val">
                  {new Date().toLocaleDateString('en-GB', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                  })}
                  ,{' '}
                  {new Date().toLocaleTimeString('en-US', {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true,
                  })}
                </span>
              </td>
            </tr>
            <tr>
              <td className="transctn">Amount Paid</td>
              <td className="text-end">
                <span className="val">${signupResponse?.finalAmount}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Link to="/login" className="btn btn-primary">
        Continue
      </Link>
    </div>
  );
  return (
    <CustomModal
      defaultHeader
      body={renderBody()}
      className="modal modal-small-width modal-micro fade modal-subscribe-success modal-subscribe-success1"
      dialgName="modal-dialog modal-dialog-centered modal-dialog-scrollable"
      contentClassName="modal-content"
      footer={renderFooter()}
      show={showModal}
      closeModal={() => {
        setShowModal(false);
      }}
      noCloseButton
    />
  );
};
