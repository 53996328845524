import { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
// Import necessary components
import Header from '../common/Header';
// Import icons
import GoogleIcon from '../../css/images/icon-google.svg';
import hidePasswordIcon from '../../css/images/icon-hide-password.svg';
import showPasswordIcon from '../../css/images/icon-show-password.svg';
// Import reducer
import useLoginReducer from './reducer/LoginReducer';
// Import Modal component and styles
import tickIcon from '../../css/images/tick.png';
import closeIcon from '../../css/images/close.svg';
import '../../css/scss/common-modal.scss';
import '../../css/scss/login-modals.scss';
import '../../css/scss/login.scss';
import CustomModal from '../common/CustomModal';
import firebase from 'firebase/app';
// import { Datastore } from '@google-cloud/datastore';

import history from '../../history';
import useAlertReducer from './reducer/AlertReducer';
// import firebaseconfig from '../../base';

export default function Login() {
  // const history = useHistory();
  // Use reducer to handle login state
  const {
    googleLogin,
    // isLoginLoading,
    // isGoogleLoading,
    authLogin,
    forgotPassword,
    errorMessage,
    successMessage,
    forgetPwdLoader,
    isLoggedIn,
  } = useLoginReducer((state) => state);
  const { error } = useAlertReducer((state) => state);
  // Necessary states for formData and error validations
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [errors, setErrors] = useState({});
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
  const [email, setEmail] = useState('');
  const [emailErr, setEmailErr] = useState('');
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [isGoogleLoading, setIsGoogleLoading] = useState(false);

  // Google login functionality
  // const signInWithGoogle = useGoogleLogin({
  //   onSuccess: ({ access_token, token_type }) => {
  //     googleLogin({
  //       token: access_token,
  //       tokenType: token_type,
  //     });
  //   },
  //   onError: () =>
  //     setErrors({ google: 'An error occurred during Google login' }),
  // });

  const auth = firebase.auth();
  // const datastore = new Datastore();
  const signInWithPopup = firebase.auth();
  const googleProvider = new firebase.auth.GoogleAuthProvider();

  // Form validation function
  const validate = () => {
    const errors = {};
    if (!formData.email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email is invalid';
    }
    if (!formData.password) {
      errors.password = 'Password is required';
    } else if (formData.password.length < 8) {
      errors.password = 'Password must be at least 8 characters long.';
    } else if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+={}[\]|\\:;,.<>?/`~]).*$/.test(
        formData.password
      )
    ) {
      errors.password =
        'Password must include a lowercase letter, an uppercase letter, a digit, and a special character';
    }

    return errors;
  };

  // Set input value to the state
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Function for the submit button
  const handleSubmit = (e, email, password) => {
    e.preventDefault();
    setIsLoginLoading(true);
    auth
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;

        user
          .getIdToken()
          .then((accessToken) => {
            localStorage.setItem('accessToken', accessToken);
            history.push('/home');
            authLogin();
          })
          .catch((error) => {
            console.error('Error getting access token:', error);
          });
        setIsLoginLoading(false);
      })
      .catch((e) => {
        setIsLoginLoading(false);
        const errorCode = e.code;
        const errorMessage = e.message;
        console.error('Error signing in:', errorCode, errorMessage);
        error(errorMessage);
      });
  };

  const handleGoogleSignIn = () => {
    setIsGoogleLoading(true);
    firebase
      .auth()
      .signInWithPopup(googleProvider)
      .then(async (result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = result.credential; // The credential from the sign-in result
        const token = credential ? credential.accessToken : null; // Get the access token

        localStorage.setItem('accessToken', token);
        history.push('/home');
        googleLogin();
        setIsGoogleLoading(false);
      })
      .catch((err) => {
        // Handle errors here
        const errorCode = err.code;
        const errorMessage = err.message;

        // The email of the user's account used
        const email = err.email;

        // The AuthCredential type that was used
        const credential = err.credential;
        setIsGoogleLoading(false);
        console.error(
          'Error during sign-in:',
          errorCode,
          errorMessage,
          email,
          credential
        );
        error(errorMessage);
      });
  };

  // Content for forgot password modal
  const renderModalBody = () => {
    return (
      <div className="modal-body modal-form">
        <form action="">
          <div className="row">
            <div className="col-md-12">
              <div className="success-ico">
                <img src={tickIcon} alt="tick" />
              </div>
              <h2 className="modal-title">Forgot Your Password?</h2>
              <p className="modal-desc">
                Please enter the email you use to sign in to Preflop.
              </p>
              <div className="form-group">
                <div className="input-wrp">
                  <label htmlFor="email" className="form-label">
                    <span>Email Address </span> <span>*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder="Enter Email iD"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <small className="text-danger">{emailErr}</small>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  };

  const validateEmail = (email) => {
    // Regular expression updated to include the + symbol
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email);
  };

  const renderModalFooter = () => {
    return (
      <div className="modal-footer">
        <button
          // to="/reset-password"
          type="button"
          className="btn btn-primary"
          onClick={() => {
            if (!email) {
              setEmailErr('Email is required');
            } else if (!validateEmail(email)) {
              setEmailErr('Invalid email format');
            } else {
              forgotPassword({ email });
              setEmailErr(''); // Clear any previous error
            }
          }}>
          {forgetPwdLoader ? 'Loading...' : 'Submit'}
        </button>
      </div>
    );
  };
  return (
    <>
      <Header />
      <div className="section section-login">
        <div className="container custom-container">
          <div className="row">
            <div className="col login-form-wrp">
              <h3 className="login-title">Login To Your Account</h3>
              <form
                action="#"
                className="login-form login"
                onSubmit={handleSubmit}>
                <div className="form-wrp">
                  <div className="input-wrp">
                    <label htmlFor="email" className="label req">
                      Email ID
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="Enter Email ID"
                      value={formData.email}
                      onChange={handleChange}
                      onBlur={validate}
                    />
                    {errors.email && (
                      <small className="text-danger">{errors.email}</small>
                    )}
                  </div>
                  <div className="input-wrp">
                    <label htmlFor="password" className="label req">
                      Password
                    </label>
                    <div className="password-field-wrp">
                      <span
                        className="show-hide"
                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                        style={{ cursor: 'pointer' }}>
                        <img
                          src={
                            !isPasswordVisible
                              ? hidePasswordIcon
                              : showPasswordIcon
                          }
                          alt=""
                          className="show"
                        />
                      </span>
                      <input
                        type={isPasswordVisible ? 'text' : 'password'}
                        name="password"
                        id="password"
                        className="form-control"
                        placeholder="password"
                        value={formData.password}
                        onChange={handleChange}
                        onBlur={validate}
                      />
                    </div>

                    {errors.password && (
                      <small className="text-danger">{errors.password}</small>
                    )}

                    {errorMessage === 'Email or password is incorrect' && (
                      <small className="text-danger">{errorMessage}</small>
                    )}
                  </div>
                  <div className="input-wrp">
                    <div className="remember-wrp">
                      <span
                        className="links links-primary forgot-link"
                        onClick={() => setForgotPasswordModal(true)}
                        style={{ cursor: 'pointer' }}>
                        Forgot Password?
                      </span>
                    </div>
                  </div>
                  <div className="input-wrp">
                    <button
                      className="btn btn-primary w-100"
                      onClick={(e) =>
                        handleSubmit(e, formData.email, formData.password)
                      }>
                      {isLoginLoading ? 'Loading...' : 'Login'}
                    </button>
                  </div>
                  <div className="input-wrp">
                    <p className="signup-txt">
                      Don’t have an account?{' '}
                      <Link to="/signup" className="links links-primary">
                        Sign up
                      </Link>
                    </p>
                  </div>
                </div>
              </form>
              <div className="sep-wrp">
                <h3 className="sep-title">OR</h3>
              </div>
              <div className="input-wrp">
                <button
                  className="btn btn-google-login w-100"
                  onClick={() => handleGoogleSignIn()}>
                  <span className="ico">
                    <img src={GoogleIcon} alt="icon-google" />
                  </span>
                  <span className="txt">
                    {isGoogleLoading ? 'Loading...' : 'Sign in with Google'}
                  </span>
                </button>
                <small className="text-danger">
                  {/* {errorMessage || errors.google} */}
                  {errors.google}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        defaultHeader
        body={renderModalBody()}
        className="modal modal-small-width modal-micro forgot-modal forgot-password fade"
        dialgName="modal-dialog modal-dialog-centered modal-dialog-scrollable"
        contentClassName="modal-content"
        footer={renderModalFooter()}
        show={forgotPasswordModal}
        closeModal={() => {
          setForgotPasswordModal(false);
          setEmailErr('');
        }}
      />
    </>
  );
}
