import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import GoogleIcon from '../../../css/images/icon-google.svg';
import ButtonArrowIcon from '../../../css/images/btn-arrow.svg';
import hidePasswordIcon from '../../../css/images/icon-hide-password.svg';
import showPasswordIcon from '../../../css/images/icon-show-password.svg';
import useSignupReducer from '../reducer/signupReducer';
import { useGoogleLogin } from '@react-oauth/google';

export const PersonalInfo = ({ currentTab, switchTab, setData, data }) => {
  const { personalInfo } = data;
  const [formData, setFormData] = useState({
    firstName: personalInfo?.firstName || '',
    lastName: personalInfo?.lastName || '',
    email: personalInfo?.email || '',
    // phone: personalInfo?.phone || '',
    password: personalInfo?.password || '',
    confirmPassword: personalInfo?.confirmPassword || '',
  });
  const [errors, setErrors] = useState({});
  const [isPasswordVisible, setIsPasswordVisible] = useState([]);

  const { checkEmail, errorMessage, successMessage, isCheckEmailLoading } =
    useSignupReducer((state) => state);

  console.log('formData', formData);

  const signInWithGoogle = useGoogleLogin({
    onSuccess: async ({ access_token, token_type }) => {
      try {
        // Fetch user info from Google's userinfo endpoint
        const userInfoResponse = await fetch(
          'https://www.googleapis.com/oauth2/v3/userinfo',
          {
            headers: {
              Authorization: `${token_type} ${access_token}`,
            },
          }
        );
        const userInfo = await userInfoResponse.json();
        if (userInfo?.email) {
          checkEmail({ email: userInfo?.email });
        }
        setFormData({
          firstName: userInfo?.given_name,
          lastName: userInfo?.family_name,
          email: userInfo?.email,
        });
        console.log('userInfo', userInfo);
      } catch (error) {
        console.error('Failed to fetch user info:', error);
        setErrors({ google: 'Failed to fetch user info' });
      }
    },
    onError: () =>
      setErrors({ google: 'An error occurred during Google login' }),
  });

  const togglePasswordVisibility = (value) => {
    setIsPasswordVisible((prevState) => {
      if (prevState.includes(value)) {
        return prevState.filter((item) => item !== value);
      } else {
        return [...prevState, value];
      }
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const passRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&])[A-Za-z\d#@$!%*?&]{8,15}$/;

  const validate = () => {
    let tempErrors = {};
    if (!formData.firstName) tempErrors.firstName = 'First Name is required';
    if (!formData.lastName) tempErrors.lastName = 'Last Name is required';
    if (!formData.email) {
      tempErrors.email = 'Email is required';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      tempErrors.email = 'Email is not valid';
    }
    if (!formData.password) {
      tempErrors.password = 'Password is required';
    } else if (formData.password.length < 8) {
      // Minimum length should be 8 according to the regex
      tempErrors.password = 'Password must be at least 8 characters';
    } else if (formData.password.length > 15) {
      // Maximum length should be 15 according to the regex
      tempErrors.password = 'Password must be no more than 15 characters';
    } else if (!passRegex.test(formData.password)) {
      tempErrors.password =
        'Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character';
    }
    if (formData.confirmPassword !== formData.password) {
      tempErrors.confirmPassword = 'Passwords do not match';
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  useEffect(() => {
    if (successMessage === 'Email does not exist') {
      switchTab('choosePlan');
      setData({ ...data, personalInfo: formData });
    }
  }, [successMessage]);

  const handleSubmit = () => {
    if (validate()) {
      setData({ ...data, personalInfo: formData });
      checkEmail({ email: formData?.email });
    }
  };

  return (
    <div
      className={`form-steps step-1 ${
        currentTab !== 'personalInfo' && 'd-none'
      }`}>
      <div className="forms-fields">
        <div className="signup-action-footer-inner">
          <div className="input-wrp">
            <button
              className="btn btn-google-login"
              onClick={() => signInWithGoogle()}>
              <span className="ico">
                <img src={GoogleIcon} alt="icon-google" />
              </span>
              <span className="txt">Sign up with Google</span>
            </button>
          </div>

          <div className="sep-wrp">
            <h3 className="sep-title">OR</h3>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="input-wrp">
              <label htmlFor="firstName" className="label req">
                First Name
              </label>
              <input
                type="text"
                name="firstName"
                className="form-control"
                placeholder="First Name"
                value={formData.firstName}
                onChange={handleInputChange}
              />
              {errors.firstName && (
                <small className="text-danger">{errors.firstName}</small>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="input-wrp">
              <label htmlFor="lastName" className="label req">
                Last Name
              </label>
              <input
                type="text"
                name="lastName"
                className="form-control"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleInputChange}
              />
              {errors.lastName && (
                <small className="text-danger">{errors.lastName}</small>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="input-wrp">
              <label htmlFor="email" className="label req">
                Email ID
              </label>
              <input
                type="text"
                name="email"
                className="form-control"
                placeholder="Enter Email address"
                value={formData.email}
                onChange={handleInputChange}
              />
              {errors.email && (
                <small className="text-danger">{errors.email}</small>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="input-wrp">
              <label htmlFor="password" className="label req">
                Create A Password
              </label>
              <div className="password-field-wrp">
                <span
                  className="show-hide"
                  fdprocessedid="52cg5d"
                  onClick={() => togglePasswordVisibility('1')}
                  style={{ cursor: 'pointer' }}>
                  <img
                    src={
                      !isPasswordVisible.includes('1')
                        ? hidePasswordIcon
                        : showPasswordIcon
                    }
                    alt=""
                    className="show"
                  />
                </span>
                <input
                  type={isPasswordVisible.includes('1') ? 'text' : 'password'}
                  name="password"
                  id="password"
                  className="form-control"
                  placeholder="password"
                  value={data.password || formData.password}
                  onChange={handleInputChange}
                />
                {errors.password && (
                  <small className="text-danger">{errors.password}</small>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="input-wrp">
              <label htmlFor="confirmPassword" className="label req">
                Confirm Password
              </label>
              <div className="password-field-wrp">
                <span
                  className="show-hide"
                  fdprocessedid="52cg5d"
                  onClick={() => togglePasswordVisibility('2')}
                  style={{ cursor: 'pointer' }}>
                  <img
                    src={
                      !isPasswordVisible.includes('2')
                        ? hidePasswordIcon
                        : showPasswordIcon
                    }
                    alt=""
                    className="show"
                  />
                </span>
                <input
                  type={isPasswordVisible.includes('2') ? 'text' : 'password'}
                  name="confirmPassword"
                  id="confirmPassword"
                  className="form-control"
                  placeholder="Confirm password"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                />
                {errors.confirmPassword && (
                  <small className="text-danger">
                    {errors.confirmPassword}
                  </small>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="signup-action-footer">
          <Link to="/" className="btn btn-primary actn-arrows prev back-hm">
            <span className="ico">
              <img src={ButtonArrowIcon} alt="next" />
            </span>
            <span className="txt">Back to Home</span>
          </Link>
          <button
            className="btn btn-primary actn-arrows next"
            onClick={handleSubmit}>
            <span className="txt">
              {isCheckEmailLoading ? 'Loading...' : 'Next'}
            </span>
            <span className="ico">
              <img src={ButtonArrowIcon} alt="next" />
            </span>
          </button>
        </div>
        <div className="signup-action-footer-inner">
          {/* <div className="sep-wrp">
            <h3 className="sep-title">OR</h3>
          </div> */}
          <div className="input-wrp">
            {/* <button
              className="btn btn-google-login"
              onClick={() => signInWithGoogle()}
            >
              <span className="ico">
                <img src={GoogleIcon} alt="icon-google" />
              </span>
              <span className="txt">Sign up with Google</span>
            </button> */}
            <div className="input-wrp mt-4">
              <p className="signup-txt">
                Already have an account?{' '}
                <Link to="/login" className="links links-primary">
                  Login
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
