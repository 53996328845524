import React, { useEffect, useState } from 'react';
import ButtonArrowIcon from '../../../css/images/btn-arrow.svg';
import questionIcon from '../../../css/images/question.svg';
import { ConfirmationModal } from './confirmationModal';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import PaymentForm from './paymentForm';
import useSignupReducer from '../reducer/signupReducer';
import { Loader } from '../../common/Loader';
import PhoneInput, { getCountryCallingCode } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import CustomSelect from '../../common/CustomSelect';
import ChecklistIcon from '../../../css/images/check-list.svg';
import closelistIcon from '../../../css/images/close-list.svg';

export const Payment = ({ currentTab, switchTab, setData, data }) => {
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );

  const {
    subscriptionSetupIntent,
    clientSecretKey,
    valdiateCouponCode,
    couponSuccessMsg,
    couponErrorMsg,
    isCouponLoading,
  } = useSignupReducer((state) => state);
  const { personalInfo, planDetails, payment } = data;
  const [formValues, setFormValues] = useState({
    firstName: payment?.firstName || personalInfo?.firstName || '',
    lastName: payment?.lastName || personalInfo?.lastName || '',
    phoneNumber: payment?.phoneNumber || personalInfo?.phone || '',
    apartment: payment?.apartment || '',
    address: payment?.address || '',
    city: payment?.city || '',
    state: payment?.state || '',
    postalCode: payment?.postalCode || '',
    cardNumber: payment?.cardNumber || '',
    expiry: payment?.expiry || '',
    cvc: payment?.cvc || '',
    couponName: payment?.couponName || '',
    countryCode: payment?.countryCode || 'IN',
  });
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [code, setCode] = useState('91');

  useEffect(() => {
    const payloadData = {
      email: personalInfo.email,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      phone: formValues.phoneNumber,
    };
    // if (planDetails?.priceId)
    subscriptionSetupIntent(planDetails?.priceId, payloadData);
  }, []);

  const handleChange = (value, key) => {
    const tempData = structuredClone(formValues);
    tempData[key] = value;
    if (Object.keys(errors).length) validate(tempData);
    setFormValues(tempData);
  };

  const validate = (tempData = formValues) => {
    const newErrors = {};
    if (!tempData?.firstName) {
      newErrors.firstName = 'FirstName is required';
    } else if (tempData.firstName.length < 3) {
      newErrors.firstName = 'FirstName must be at least 3 characters long';
    }
    if (!tempData?.lastName) {
      newErrors.lastName = 'Last Name is required';
    }
    if (!tempData?.address) {
      newErrors.address = 'Address is required';
    } else if (tempData.address.length < 10) {
      newErrors.address = 'Address must be at least 10 characters long';
    } else if (tempData.address.length > 100) {
      // Adjust max length as needed
      newErrors.address = 'Address must be no more than 100 characters long';
    }

    if (!tempData?.postalCode) {
      newErrors.postalCode = 'PostalCode is required';
    }
    if (!tempData?.phoneNumber) {
      newErrors.phoneNumber = 'Mobile Number is required';
    }
    if (!tempData?.city) {
      newErrors.city = 'City is required';
    }
    if (!tempData?.state) {
      newErrors.state = 'State is required';
    }
    setErrors(newErrors);

    if (!Object.keys(newErrors).length) {
      setData({ ...data, payment: formValues });
    }
    return newErrors;
  };

  const handleSubmit = () => {
    const newErrors = validate();
    if (!Object.keys(newErrors).length) {
      setData({ ...data, payment: formValues });
    }
  };

  const appearance = {
    theme: 'night',
    variables: {
      fontFamily: 'Barlow, sans-serif',
      fontWeightNormal: '400',
      borderRadius: '29px',
      // colorBackground: '#0A2540',
      // colorPrimary: '#EFC078',
      // accessibleColorOnColorPrimary: '#1A1B25',
      colorText: 'white',
      colorTextSecondary: 'white',
      colorTextPlaceholder: '#fff',
      tabIconColor: 'white',
      logoColor: 'dark',
    },
    rules: {
      '.Input': {
        backgroundColor: '#00000080',
        border: '1px solid #222',
        padding: '18px',
        marginBottom: '10px',
      },
    },
  };

  const handleCountryChange = (countryCode) => {
    if (countryCode) {
      const callingCode = getCountryCallingCode(countryCode);
      setCode(callingCode);
    }
  };
  return (
    <>
      <div
        className={`form-steps step-3 ${currentTab !== 'payment' && 'd-none'}`}
      >
        <div className="forms-fields">
          <div className="row">
            <div className="col-md-6 left-sec">
              <div className="inner-title">Billing Address</div>
              <div className="trans-blk">
                <div className="input-wrp">
                  <label htmlFor="firstName" className="label req">
                    First Name
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    className="form-control"
                    placeholder="First Name"
                    value={formValues.firstName}
                    onChange={({ target: { value } }) =>
                      handleChange(value, 'firstName')
                    }
                  />
                  {errors.firstName && (
                    <small className="text-danger">{errors.firstName}</small>
                  )}
                </div>
                <div className="input-wrp">
                  <label htmlFor="lastName" className="label req">
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    className="form-control"
                    placeholder="Last Name"
                    value={formValues.lastName}
                    onChange={({ target: { value } }) =>
                      handleChange(value, 'lastName')
                    }
                  />
                  {errors.lastName && (
                    <small className="text-danger">{errors.lastName}</small>
                  )}
                </div>
                <div className="input-wrp">
                  <label htmlFor="phoneNumber" className="label req">
                    Phone Number
                  </label>
                  {/* <input
                    type="text"
                    name="phoneNumber"
                    className="form-control"
                    placeholder="phone number"
                    value={formValues.phoneNumber}
                    onChange={handleChange}
                  /> */}
                  {/* <PhoneInput
                    maxLength={11}
                    defaultCountry="IN"
                    placeholder="Phone Number"
                    className="form-control floating-input phone_number"
                    value={formValues.phoneNumber}
                    onChange={(value) => handleChange(value, 'phoneNumber')}
                  /> */}
                  <PhoneInput
                    maxLength={15}
                    defaultCountry="IN"
                    placeholder="Phone Number"
                    className="form-control floating-input phone_number"
                    value={formValues.phoneNumber}
                    onChange={(value) => handleChange(value, 'phoneNumber')}
                    international
                    countryCallingCodeEditable={false}
                    onCountryChange={handleCountryChange} // Use the handler to extract the calling code
                  />
                  {errors.phoneNumber && (
                    <small className="text-danger">{errors.phoneNumber}</small>
                  )}
                </div>
                <div className="input-wrp">
                  <label htmlFor="address" className="label req">
                    Address
                  </label>
                  <textarea
                    name="address"
                    className="form-control text-sec"
                    value={formValues.address}
                    placeholder="Address"
                    onChange={({ target: { value } }) =>
                      handleChange(value, 'address')
                    }
                  ></textarea>
                  {errors.address && (
                    <small className="text-danger">{errors.address}</small>
                  )}
                </div>
                <div className="expiry-field">
                  <div className="input-wrp">
                    <label htmlFor="city" className="label req">
                      City
                    </label>
                    <input
                      type="text"
                      name="City"
                      className="form-control"
                      placeholder="City"
                      value={formValues.city}
                      onChange={({ target: { value } }) =>
                        handleChange(value, 'city')
                      }
                    />
                    {errors.city && (
                      <small className="text-danger">{errors.city}</small>
                    )}
                  </div>
                  <div className="input-wrp">
                    <label htmlFor="state" className="label req">
                      State
                    </label>
                    <input
                      type="text"
                      name="apartment"
                      className="form-control"
                      placeholder="State"
                      value={formValues.state}
                      onChange={({ target: { value } }) =>
                        handleChange(value, 'state')
                      }
                    />
                    {errors.state && (
                      <small className="text-danger">{errors.state}</small>
                    )}
                  </div>
                </div>
                <div className="expiry-field">
                  <div className="input-wrp">
                    <label htmlFor="postalCode" className="label req">
                      Postal Code
                    </label>
                    <input
                      type="text"
                      name="postalCode"
                      className="form-control"
                      placeholder="Postal Code"
                      maxLength={8}
                      value={formValues.postalCode}
                      onChange={({ target: { value } }) => {
                        const numericValue = value.replace(/\D/g, ''); // Remove any non-numeric characters
                        handleChange(numericValue, 'postalCode');
                      }}
                    />

                    {errors.postalCode && (
                      <small className="text-danger">{errors.postalCode}</small>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="inner-title">Payment Info</div>
              <div className="input-wrp">
                <label htmlFor="firstName" className="label">
                  Coupon Code
                </label>
                <div className="coupn-wrp">
                  <input
                    type="text"
                    name="couponName"
                    className="form-control"
                    placeholder="coupon code"
                    value={formValues.couponName}
                    onChange={({ target: { value } }) =>
                      handleChange(value, 'couponName')
                    }
                  />
                  <button
                    className="btn btn-primary actn-arrows"
                    onClick={() =>
                      valdiateCouponCode({
                        couponName: formValues.couponName,
                        priceId: planDetails.priceId,
                      })
                    }
                  >
                    {isCouponLoading ? 'Loading...' : 'Apply'}
                  </button>
                </div>
                {errors.couponName && (
                  <small className="text-danger">{errors.couponName}</small>
                )}
                {couponErrorMsg && (
                  <span className="text-danger coupon-msg">
                    <img src={closelistIcon} />
                    {couponErrorMsg}
                  </span>
                )}
                {couponSuccessMsg && (
                  <span className="text-success coupon-msg">
                    <img src={ChecklistIcon} />
                    {couponSuccessMsg}
                  </span>
                )}
              </div>
              {clientSecretKey ? (
                <Elements
                  stripe={stripePromise}
                  options={{
                    clientSecret: clientSecretKey,
                    appearance,
                    paymentMethodCreation: 'manual',
                    locale: 'en',
                  }}
                  // StripeElementsOptions={{
                  //   paymentMethodCreation: 'manual',
                  //   locale: 'en',
                  //   // appearance: {
                  //   //   theme: 'flat',
                  //   //   variables: {
                  //   //     borderRadius: '50px',
                  //   //   },
                  //   // },
                  // }}
                >
                  <PaymentForm
                    planDetails={planDetails}
                    data={formValues}
                    personalDetails={personalInfo}
                    handleValidation={validate}
                    code={code}
                  />
                </Elements>
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </div>
        <div className="signup-action-footer">
          <button
            className="btn btn-primary actn-arrows prev"
            onClick={() => switchTab('choosePlan')}
          >
            <span className="ico">
              <img src={ButtonArrowIcon} alt="next" />
            </span>
            <span className="txt">Prev</span>
          </button>
        </div>
      </div>
    </>
  );
};
