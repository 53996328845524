import '../../css/scss/subscription.scss';
import planTickIcon from '../../css/images/plan-tick.svg';
import BasicPlanIcon from '../../css/images/basic.svg';
import ProPlanIcon from '../../css/images/pro.svg';
import CallIcon from '../../css/images/call.svg';
import SmsIcon from '../../css/images/sms.svg';
// import ChecklistIcon from '../../css/images/check-list.svg';
import { useEffect } from 'react';
import subscriptionReducer from './reducer/subscriptionReducer';
import { Loader } from '../common/Loader';
import FreePlanIcon from '../../css/images/free-plan.svg';
import Loading from '../Loading';

export const Subscription = () => {
  const { profile, profileData, isProfileLoading, cancelSubscription } =
    subscriptionReducer((state) => state);

  console.log('profileData', profileData);
  useEffect(() => {
    profile();
  }, []);
  const handlePlanIcon = (amount) => {
    if (amount === 0) {
      return FreePlanIcon;
    } else if (amount <= 500) {
      return BasicPlanIcon;
    } else if (amount > 500) {
      return ProPlanIcon;
    } else return null;
  };
  console.log('profileData', profileData);
  function getRemainingDays(expiryDateStr) {
    const expiryDate = new Date(expiryDateStr);
    const currentDate = new Date();

    // Calculate the difference in milliseconds
    const differenceInMs = expiryDate - currentDate;

    // Convert the difference from milliseconds to days
    const remainingDays = Math.ceil(differenceInMs / (1000 * 60 * 60 * 24));

    return remainingDays;
  }
  return (
    <div className="subscription">
      <div className="container custom-container">
        <div className="row loader-inner">
          {isProfileLoading ? (
            <Loading />
          ) : profileData.isVip ? (
            'You are a VIP user'
          ) : (
            profileData?.plan && (
              <>
                <div className="subscription-box">
                  <h3 className="mb-4">Subscription Plan</h3>
                  <div className="box-inner">
                    <div className="current-plan">
                      {profileData.subscription_status && (
                        <>
                          <span className="current-checkbox">
                            <img src={planTickIcon} />
                          </span>
                          <span>CURRENT PLAN</span>
                        </>
                      )}
                    </div>
                    <div className="row align-items-center box-sec">
                      {/* First Column */}
                      <div className="d-flex align-items-center text-inner">
                        <div className="icon">
                          {' '}
                          <img
                            src={handlePlanIcon(profileData?.plan?.amount)}
                            alt=""
                            className
                          />
                        </div>
                        <div className="detail-sec">
                          <h4 className="mb-0">{profileData?.plan?.name}</h4>
                          <p className="text-sec">
                            <span className="nmbr">
                              {profileData?.remainingTime}
                            </span>{' '}
                          </p>
                        </div>
                      </div>
                      {/* Second Column */}
                      <div className="border-start price-sec">
                        <div className="pricing">
                          <span className="dollar">$ </span>
                          <span className="price">
                            {profileData?.plan?.amount}
                          </span>{' '}
                          <span className="cycle">
                            /{profileData?.plan?.period}
                          </span>{' '}
                        </div>
                        <div className="list-items">
                          <ul className="custom-checkbox-list">
                            <li>
                              <label className="custom-checkbox">
                                <input
                                  type="checkbox"
                                  defaultChecked
                                  disabled
                                />
                                <span className="checkmark" />
                                All Pro Plan Features
                              </label>
                            </li>
                            <li>
                              <label className="custom-checkbox">
                                <input type="checkbox" disabled />
                                <span className="checkmark" />
                                Beta Access
                              </label>
                            </li>
                            <li>
                              <label className="custom-checkbox">
                                <input type="checkbox" disabled />
                                <span className="checkmark" />
                                Lorem ipsum
                              </label>
                            </li>
                          </ul>
                        </div>
                      </div>
                      {profileData.subscription_status ? (
                        <div className="cancel-btn">
                          {!profileData.plan.freePlan && (
                            <button
                              type="button"
                              className="btn btn-cancel"
                              onClick={() =>
                                cancelSubscription({
                                  subscriptionId: profileData.subscription_id,
                                })
                              }>
                              Cancel Subscription
                            </button>
                          )}
                        </div>
                      ) : (
                        <div className="contact-admin">
                          <div className="text">
                            Need help? Reach out to our admin for support!
                          </div>
                          <div className="right-sec">
                            <div className="links">
                              <a
                                href="mailto:Info@gmail.com"
                                className="subtitle">
                                <span className="ico">
                                  <img src={SmsIcon} alt="Email Icon" />
                                </span>
                                <span>info@gmail.com</span>
                              </a>
                            </div>
                            <div className="links">
                              <a href="tel:+917037019964" className="subtitle">
                                <span className="ico">
                                  <img src={CallIcon} alt="Phone Icon" />
                                </span>

                                <span>703-701-9964</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* <div className="contact-admin">
                    <div className="text">If your plan has expired, please reach out to us</div>
                    <div className="right-sec">
                      <div className="links">
                        <a  href="mailto:Info@gmail.com" className="subtitle">
                          <span className="ico">
                            <img  src={CallIcon} alt="Email Icon"/></span>
                            <span>infogmail.com</span>
                            </a></div>
                            <div className="links">
                              <a href="tel:+917037019964" className="subtitle">
                                 <span  className="ico">
                                  <img src={SmsIcon} alt="Phone Icon"/></span> 
                                  
                                  <span>703-701-9964</span>
                                  </a>
                                  </div>
                                  </div>
                                  </div> */}
                </div>
              </>
            )
          )}
        </div>
      </div>
    </div>
  );
};
