import React, { useEffect, useState } from 'react';
import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import { ConfirmationModal } from './confirmationModal';
import useSignupReducer from '../reducer/signupReducer';

const PaymentForm = ({
  planDetails,
  data,
  personalDetails,
  handleValidation,
  code,
}) => {
  console.log('code', code);
  const stripe = useStripe();
  const elements = useElements();
  const {
    createSubscription,
    customerId,
    setupIntentId,
    subscriptionResponse,
    createUser,
    isSignUpLoading,
    createSubscriptionLoading,
    couponSuccessMsg,
    signupResponse,
    discountPrice,
    subscriptionData,
  } = useSignupReducer((state) => state);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setErrorMessage(null);
    if (
      subscriptionData?.subscriptionStatus === 'active' ||
      subscriptionData?.subscriptionStatus === 'trialing'
    ) {
      createUser(
        {
          firstName: data.firstName,
          lastName: data.lastName,
          email: personalDetails.email,
          phone: data.phoneNumber,
          password: personalDetails.password,
          confirmPassword: personalDetails.confirmPassword,
          countryCode: code
            ? `+${code}`
            : data?.phoneNumber?.substring(0, 3) || '+91',

          stripeCustomerId: customerId,
          planId: planDetails.id,
          address: data?.address,
        },
        setShowModal
      );
    }
    if (subscriptionData?.subscriptionStatus === 'incomplete') {
      confirmPayment();
    }
  }, [subscriptionData]);
  const confirmPayment = async () => {
    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      clientSecret: subscriptionData.client_secret,
      confirmParams: {
        // return_url: 'http://localhost:3000/signup',
        payment_method_data: {
          billing_details: {
            name: data.firstName + data.lastName,
            email: personalDetails.email,
            address: {
              line1: data.address,
              postal_code: data.postalCode,
              city: data.city,
              state: data.state,
              country: data.countryCode,
            },
          },
        },
      },
      redirect: 'if_required', // or 'always'
    });
    if (error) {
      setErrorMessage(error.message);
    } else if (paymentIntent && paymentIntent.status === 'succeeded') {
      //   setShowModal(true);
      createUser(
        {
          firstName: data.firstName,
          lastName: data.lastName,
          email: personalDetails.email,
          phone: data.phoneNumber,
          password: personalDetails.password,
          confirmPassword: personalDetails.confirmPassword,
          countryCode: code
            ? `+${code}`
            : data?.phoneNumber?.substring(0, 3) || '+91',
          stripeCustomerId: customerId,
          planId: planDetails.id,
          address: data?.address,
        },
        setShowModal
      );
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const newErrors = handleValidation();
    if (Object.keys(newErrors).length) {
      return;
    }
    if (!stripe || !elements) {
      return;
    }
    setIsPaymentLoading(true);

    const elementData = await elements.submit();
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      elements,
      params: {
        type: 'card',
        billing_details: {
          name: personalDetails.firstName,
          email: personalDetails.email,
          address: {
            line1: data.address,
            postal_code: data.postalCode,
            city: data.city,
            state: data.state,
            country: data.countryCode,
          },
        },
      },
    });
    if (couponSuccessMsg) {
      createSubscription({
        customerId: customerId,
        paymentMethodId: paymentMethod.id,
        setUpIntentId: setupIntentId,
        priceId: planDetails.priceId,
        couponName: data.couponName,
      });
    } else {
      createSubscription({
        customerId: customerId,
        paymentMethodId: paymentMethod.id,
        setUpIntentId: setupIntentId,
        priceId: planDetails.priceId,
      });
    }
    setIsPaymentLoading(false);
  };
  console.log('planDetails', planDetails);
  return (
    <>
      <form onSubmit={handleSubmit}>
        <PaymentElement
          id="card-element"
          options={{
            fields: {
              billingDetails: {
                address: {
                  country: 'never',
                  postalCode: 'never',
                },
              },
            },
          }}
        />
        {errorMessage && <small className="text-danger">{errorMessage}</small>}
        <div className="payment-sec">
          {planDetails?.trialPeriod < 1 && (
            <label className="amt-sum-blk">
              Amount: ${discountPrice || planDetails.amount}
            </label>
          )}
          <button
            disabled={isPaymentLoading || !stripe || !elements}
            className="btn btn-primary actn-arrows next pay-btn ">
            <span className="txt">
              {isSignUpLoading || createSubscriptionLoading
                ? 'Processing...'
                : 'Subscribe'}
            </span>
          </button>
        </div>
      </form>
      {showModal && (
        <ConfirmationModal
          showModal={showModal}
          setShowModal={setShowModal}
          planDetails={planDetails}
          signupResponse={signupResponse}
        />
      )}
    </>
  );
};

export default PaymentForm;
