import { useState } from 'react';
import CustomModal from '../../common/CustomModal';
import useSignupReducer from '../reducer/signupReducer';
import ChecklistIcon from '../../../css/images/check-list.svg';
import closelistIcon from '../../../css/images/close-list.svg';

const ActivationModal = ({ setShowModal, showModal, personalInfo }) => {
  const {
    validateActivationCode,
    codeSuccessMsg,
    isCodeLoading,
    codeErrorMsg,
    isSignupLoading,
    createUser,
    resetActivation,
  } = useSignupReducer((state) => state);
  const [activationCode, setActivationCode] = useState('');
  const renderBody = () => (
    <div className="modal-body modal-form">
      <form action="">
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="" className="form-label">
                Activation Code
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Activation Code"
                // value={activationCode}
                onChange={(e) => {
                  setActivationCode(e.target.value);
                  resetActivation();
                }}
              />
              {codeErrorMsg && (
                <span className="text-danger coupon-msg">
                  <img src={closelistIcon} />
                  {codeErrorMsg}
                </span>
              )}
              {codeSuccessMsg && (
                <span className="text-success coupon-msg">
                  <img src={ChecklistIcon} />
                  {codeSuccessMsg}
                </span>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );

  const renderFooter = () => (
    <div className="modal-footer">
      {/* <button
        type="button"
        className="btn btn-secondary"
        data-bs-dismiss="modal"
        onClick={() => setShowModal(false)}>
        Cancel
      </button> */}
      {!codeSuccessMsg && !codeErrorMsg && (
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => validateActivationCode({ code: activationCode })}>
          {isCodeLoading ? 'Loading...' : 'Submit'}
        </button>
      )}
      {codeSuccessMsg && (
        <button
          type="button"
          className="btn btn-primary"
          onClick={() =>
            createUser(
              {
                firstName: personalInfo.firstName,
                lastName: personalInfo.lastName,
                email: personalInfo.email,
                password: personalInfo.password,
                confirmPassword: personalInfo.confirmPassword,
                vipUser: true,
                //   planId: planDetails?.id,
              },
              null,
              { toLogin: true }
            )
          }>
          {isSignupLoading ? 'Loading...' : 'Signup as a VIP'}
        </button>
      )}
      {codeErrorMsg && (
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            setShowModal(false);
            resetActivation();
          }}>
          Cancel
        </button>
      )}
    </div>
  );

  return (
    <CustomModal
      defaultHeader
      body={renderBody()}
      className="modal modal-small-width modal-micro fade activation-modal-signup"
      dialgName="modal-dialog modal-dialog-centered modal-dialog-scrollable"
      contentClassName="modal-content"
      footer={renderFooter()}
      show={showModal}
      closeModal={() => {
        setShowModal(false);
        resetActivation();
      }}
      //   noCloseButton
    />
  );
};

export default ActivationModal;
