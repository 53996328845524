import React, { createRef, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  withRouter,
} from 'react-router-dom';
import { UserContext } from './UserContext';
import base from '../base';
import firebase from 'firebase';
// UI
import Loading from './Loading';
import NavBar from './NavBar';
import SideBar from './SideBar';
// Components to Route to
import Dashboard from './Dashboard/Dashboard';
import Upload from './Upload/components/Upload';
import DRD from './DRD/components/DRD';
import RangeTrainer from './RangeTrainer/components/RangeTrainer';
import TrainingData from './TrainingData/components/TrainingData';
import TreeManager from './TreeManager/components/TreeManager';
import Settings from './Settings/components/Settings';
import Feedback from './Feedback';
import Subscribe from './Subscribe';
// Login/authentication
import Splash from './Splash';
import { getDate } from '../helpers';
import getUserLastLogin from './Admin/getUserLastLogin';
import getTreeNamesAndIDs from './Admin/getTreeNamesAndIDs';
import verifyUser from './Admin/verifyUser';
import addTreeToUser from './Admin/addTreeToUser';
import removeTreeFromUser from './Admin/removeTreeFromUser';
import MassPioExport from './Tools/MassPioExport';
import Tools from './Tools/Tools';
import 'normalize.css';
import verifyBasicUser from './Admin/verifyBasicUser';
import deleteTree from './Admin/deleteTree';
import Accounting from './Accounting/components/Accounting';
import sendVerificationRequest from './VerificationRequest';
import Login from './Login/Login';
import Signup from './Signup/Signup';
import ResetPassword from './ResetPassword/resetPassword';
import Footer from './common/Footer';
import '../css/scss/common.scss';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Suspense } from 'react';
import useLoginReducer from './Login/reducer/LoginReducer';
import { ToastContainer, Zoom } from 'react-toastify';

import Header from './common/Header';
import { Subscription } from './Subscription/subscription';
import { Toaster } from './common/Toaster';
import { PublicRoute } from './publicRoute';
import useAlertReducer from './Login/reducer/AlertReducer';
import subscriptionReducer from './Subscription/reducer/subscriptionReducer';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      userIsLoggedIn: 'pending',
      userIsVerified: 'pending',
      userInfo: {
        name: null,
        uid: null,
        treePermissions: null,
      },
      currentTrees: [],
      privateMode: true,
    };
    this.historyRef = createRef(); // Create a ref to hold history
  }

  async componentDidMount() {
    const { isLoggedIn, history, location, profileAction } = this.props;

    if (isLoggedIn) profileAction();

    // Check if the current route is '/'
    if (location.pathname === '/') {
      if (isLoggedIn) {
        history.push('/home');
      }
    }
    firebase.auth().onAuthStateChanged(async (user) => {
      firebase.analytics().logEvent('login');
      if (user) {
        let previousLogin = await base.fetch(`users/${user.uid}/lastLogin`, {
          context: this,
        });
        if (!Object.keys(previousLogin).length) {
          base.post(`users/${user.uid}/verified`, { data: true });
          sendVerificationRequest(user.uid, user.displayName);
        }
        base.update(`users/${user.uid}`, {
          data: {
            name: user.displayName,
            email: user.email,
            lastLogin: getDate(),
          },
        });
        base.update(`userList`, {
          data: { [user.uid]: user.displayName },
        });
        this.setState({
          userInfo: { name: user.displayName, uid: user.uid },
          userIsLoggedIn: true,
        });
        base
          .fetch(`users/${user.uid}/verified`, { context: this })
          .then(async (userIsVerified) => {
            if (userIsVerified === true) {
              let treePermissions, currentTree, currentTrees, settings;
              await base
                .fetch(`treePermissions/${user.uid}`, { context: this })
                .then((tpData) => {
                  treePermissions = tpData;
                });
              await base
                .fetch(`users/${user.uid}/currentTrees`, { context: this })
                .then((ctsData) => {
                  currentTrees = ctsData;
                });
              this.treesRef = base.syncState(`users/${user.uid}/currentTrees`, {
                context: this,
                state: 'currentTrees',
                defaultValue: [],
                then: this.setState({
                  userInfo: { ...this.state.userInfo, treePermissions },
                  currentTrees,
                  loading: false,
                  userIsVerified,
                }),
              });
              this.settingsRef = base.syncState(`users/${user.uid}/settings`, {
                context: this,
                state: 'settings',
                defaultValue: {
                  rtRngLowToHigh: false,
                  rtFastDeal: false,
                  rtTightHandSelect: false,
                  rtOptions: {},
                  rtShowPrevFreq: '<100',
                  rtProportionalDeal: false,
                  rtProportionalScenarios: false,
                  drdLargeDisplays: false,
                  multiTreeSelect: false,
                },
              });
            } else this.setState({ userIsVerified: false });
          });
      } else this.setState({ userIsLoggedIn: false });
    });
  }

  componentWillUnmount() {
    this.treesRef && base.removeBinding(this.treesRef);
    this.settingsRef && base.removeBinding(this.settingsRef);
  }

  handleTreeChange = (tree) => this.setState({ currentTree: tree });
  handleTreesChange = (trees) => this.setState({ currentTrees: trees });
  handleSettingsUpdate = (property, value) => {
    const settings = this.state.settings;
    settings[property] = value;
    this.setState({ settings });
  };

  logout = async () => {
    await firebase.auth().signOut();

    // Remove accessToken and authData from local storage
    localStorage.removeItem('accessToken');
    localStorage.removeItem('authData');
    localStorage.removeItem('firebase:previous_websocket_failure');
    localStorage.removeItem('loglevel');

    // Remove items from session storage
    sessionStorage.removeItem('signupTab');
    sessionStorage.removeItem('signupData');

    this.setState({
      userIsLoggedIn: false,
      userIsVerified: false,
      userInfo: {
        name: null,
        uid: null,
        treePermissions: null,
      },
      currentTrees: [],
    });

    // window.location.reload();
    // Navigate to home page
    if (this.historyRef.current) {
      this.historyRef.current.push('/');
    }

    return true;
  };

  // Dev/Admin functions //

  getUserLastLogin = () => getUserLastLogin(this.state.userInfo.uid);
  getTreeNamesAndIDs = (alphaSort = true) =>
    getTreeNamesAndIDs(this.state.userInfo.uid, alphaSort);
  verifyUser = (userToVerify) =>
    verifyUser(this.state.userInfo.uid, userToVerify);
  verifyBasicUser = (userToBasicify) =>
    verifyBasicUser(this.state.userInfo.uid, userToBasicify);
  addTreeToUser = (user, treeID, treeName) =>
    addTreeToUser(this.state.userInfo.uid, user, treeID, treeName);
  removeTreeFromUser = (user, treeID) =>
    removeTreeFromUser(this.state.userInfo.uid, user, treeID);
  deleteTree = (treeID, deleteReps = false, trackDeletion = true) =>
    deleteTree(this.state.userInfo.uid, treeID, deleteReps, trackDeletion);

  // ------------------ //
  componentDidUpdate(prevProps) {
    // Only navigate if the isLoggedIn prop changes
    if (prevProps.isLoggedIn !== this.props.isLoggedIn) {
      if (this.props.isLoggedIn) {
        this.props.history.push('/home');
      } else {
        this.props.history.push('/');
      }
    }
  }

  togglePrivateMode = (value) => {
    this.setState({
      privateMode: value,
    });
  };

  render() {
    const { isLoggedIn, isGoogleLoading, isLoginLoading, value, profileData } =
      this.props;


    if (!isLoggedIn) {
      return (
        <>
          {value && (
            <ToastContainer
              autoClose={1000}
              closeButton
              transition={Zoom}
              icon={false}
              theme="light"
            />
          )}

          <Toaster />
          {/* <Router> */}
          <Suspense fallback={<div />}>
            {/* <Switch> */}
            <PublicRoute exact path="/" component={Splash} />
            <GoogleOAuthProvider
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            >
              <PublicRoute exact path="/login" component={Login} />
              <PublicRoute exact path="/signup" component={Signup} />
            </GoogleOAuthProvider>
            <PublicRoute
              exact
              path="/reset-password/:token"
              component={ResetPassword}
            />
            {/* </Switch> */}
            <Footer />
          </Suspense>
          {/* </Router> */}
        </>
      );
    }

    if (
      isGoogleLoading ||
      isLoginLoading ||
      this.state.userIsVerified === false
    ) {
      return <Loading pulse={false} />;
    }

    return (
      <>
        {value && (
          <ToastContainer
            autoClose={1000}
            closeButton
            transition={Zoom}
            icon={false}
            theme="light"
          />
        )}

        <Toaster />
        {/* <Router> */}
        <UserContext.Provider
          value={{
            ...this.state.userInfo,
            currentTrees: this.state.currentTrees,
            handleTreeChange: this.handleTreeChange,
            handleTreesChange: this.handleTreesChange,
            settings: this.state.settings,
            handleSettingsUpdate: this.handleSettingsUpdate,
          }}
        >
          <div id="body-container">
            <Header
              privateMode={this.state.privateMode}
              togglePrivateMode={this.togglePrivateMode}
            />
            <div
              id="main-content"
              className={!profileData?.subscription_status ? 'v-txt' : ''}
            >
              {/* <Switch> */}
              <Route exact path="/home" component={Dashboard} />
              <Route exact path="/upload" component={Upload} />
              <Route exact path="/drd" component={DRD} />
              <Route exact path="/trainer" component={RangeTrainer} />
              <Route exact path="/data" component={TrainingData} />
              <Route exact path="/trees" component={TreeManager} />
              <Route exact path="/feedback" component={Feedback} />
              <Route exact path="/settings" component={Settings} />
              <Route
                exact
                path="/tools/pio-mass-export"
                component={MassPioExport}
              />
              <Route exact path="/tools" component={Tools} />
              <Route exact path="/accounting" component={Accounting} />
              <Route exact path="/subscribe" component={Subscribe} />
              <Route exact path="/subscription" component={Subscription} />
              {/* </Switch> */}
            </div>
            <Footer />
          </div>
        </UserContext.Provider>
        {/* </Router> */}
      </>
    );
  }
}

function withLoginState(WrappedComponent) {
  return function WrappedComponentWithLoginState(props) {
    const loginState = useLoginReducer((state) => state.isLoggedIn);
    const value = useAlertReducer((state) => state.value);
    const profileAction = subscriptionReducer((state) => state.profile);
    const profileData = subscriptionReducer((state) => state.profileData);

    return (
      <WrappedComponent
        isLoggedIn={loginState}
        value={value}
        profileAction={profileAction}
        profileData={profileData}
        {...props}
      />
    );
  };
}

export default withRouter(withLoginState(App));
