import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../UserContext";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import fetchTreeMetadata from "../functions/fetchTreeMetadata";
import cleanTreeOptions from "../functions/cleanTreeOptions";
import fetchTreePlayerCount from "../functions/fetchTreePlayerCount";
import fetchTreeGameType from "../functions/fetchTreeGameType";
import base from "../../../base";

const matchingTreeFilter = (options, inputValue, currentTrees, playerCountMatch, gameFeaturesMatch, limit) => {
	if (currentTrees.length === 0 && inputValue === "") return options;

	// if the search term isn't in there, don't return the option - this is basically trying to simply replicate the default functionality
	const textMatchingOptions = options.filter((option) => {
		if (option.treeName.toLowerCase().includes(inputValue.toLowerCase())) return true;
		return false;
	});
	if (currentTrees.length === 0) return textMatchingOptions;

	let filteredOptions = textMatchingOptions;
	// When the limit is 1, rather than restrict options to compatible trees,
	// The new selection will replace the old
	if (limit !== 1) {
		filteredOptions = textMatchingOptions.filter((option) => {
			if (option.gameType !== currentTrees[0].gameType) return false;
			// if playerCount needs to match (ie: DRD) then first check that it does
			if (playerCountMatch && option.playerCount !== currentTrees[0].playerCount) return false;
			// otherwise, check that the other properties of the tree are compatible
			if (
				gameFeaturesMatch &&
				// (option.site !== currentTrees[0].site || option.stake !== currentTrees[0].stake || option.rake !== currentTrees[0].rake)
				option.rake !== currentTrees[0].rake
			)
				return false;

			return true;
		});
	}
	return filteredOptions;
};

const checkSelectionLimit = (currentTrees, limit) => {
	if (!limit || limit === 1) return false;
	if (currentTrees.length >= limit) return true;
};

export default function MultiTreeSelector({
	loadTrees = null,
	selectionLimit = 0,
	playerCountMatch = false,
	gameFeaturesMatch = false,
	userBeingViewed = null,
}) {
	const userContext = useContext(UserContext);
	const currentTrees = userContext.currentTrees;
	const [treeOptions, setTreeOptions] = useState([]);
	const [inputValue, setInputValue] = useState("");
	const [maxSelections, setMaxSelections] = useState(selectionLimit);

	useEffect(() => {
		if (!userContext.settings?.multiTreeSelect) setMaxSelections(1);
		getUsersTreeData();
	}, [userBeingViewed]);
	async function getUsersTreeData() {
		const promises = [];
		let trees;
		if (!userBeingViewed) trees = userContext.treePermissions;
		else trees = await base.fetch(`treePermissions/${userBeingViewed}`, { context: this });
		if(trees){
			Object.keys(trees).forEach((treeID) => {
				promises.push(treeID);
				promises.push(fetchTreeMetadata(treeID));
				promises.push(fetchTreePlayerCount(treeID));
				promises.push(fetchTreeGameType(treeID));
			});
			const rawTreeOptions = await Promise.all(promises);
			const cleanedTreeOptions = cleanTreeOptions(rawTreeOptions);
			setTreeOptions(cleanedTreeOptions);
		}
	}

	const handleChange = async (newValue) => {
		if (maxSelections === 1 && newValue.length > 1) newValue = [newValue.pop()];
		// handle app state change
		await userContext.handleTreesChange(newValue);
		// "reload" the parent component's trees
		if (typeof loadTrees === "function") loadTrees();
	};

	return (
		<div className="multi-tree-select">
			<Autocomplete
				multiple
				size="small"
				options={treeOptions}
				// for some reason empty currentTree array was turning into an object, which bricks the Autocomplete
				// I suspect firebase syncstate has to do with it
				// this 'workaround' to check if currentTrees is an array works for now
				value={Array.isArray(currentTrees) ? currentTrees : []}
				inputValue={inputValue}
				onChange={(e, newValue) => handleChange(newValue)}
				onInputChange={(e, newInputValue) => setInputValue(newInputValue)}
				filterOptions={(options) =>
					matchingTreeFilter(options, inputValue, currentTrees, playerCountMatch, gameFeaturesMatch, maxSelections)
				}
				getOptionLabel={(option) => userContext.treePermissions[option.id]}
				getOptionSelected={(option, value) => value.id === option.id}
				getOptionDisabled={() => checkSelectionLimit(currentTrees, maxSelections)}
				filterSelectedOptions
				noOptionsText="No more compatible trees"
				renderInput={(params) => (
					<TextField
						{...params}
						label="Select Trees"
						// prevents backspace deleting selected 'tags'
						onKeyDown={(e) => {
							if (e.key === "Backspace" && inputValue === "") e.stopPropagation();
						}}
						placeholder={checkSelectionLimit(currentTrees, maxSelections) ? `Tree limit (${maxSelections}) reached` : ""}
					/>
				)}
			/>
		</div>
	);
}

// https://mui.com/components/autocomplete/#controlled-states
// https://mui.com/api/autocomplete/
