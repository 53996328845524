import { useEffect, useState } from 'react';
//import icons
import ButtonArrowIcon from '../../css/images/btn-arrow.svg';
//import styles
import '../../css/scss/signup-form.scss';
import Header from '../common/Header';
import GoogleIcon from '../../css/images/icon-google.svg';
import { PersonalInfo } from './components/personalInfo';
import { Plans } from './components/plans';
import { Payment } from './components/payment';

export default function Signup({ history }) {
  const [tab, setTab] = useState(() => {
    // Initialize the state from session storage if it exists, otherwise use 'personalInfo'
    return sessionStorage.getItem('signupTab') || 'personalInfo';
  });
  const [signupData, setSignupData] = useState(
    () =>
      JSON.parse(sessionStorage.getItem('signupData')) || {
        personalInfo: {},
        payment: {},
        planDetails: {},
      }
  );
  useEffect(() => {
    // Store the current state in session storage whenever it changes
    sessionStorage.setItem('signupTab', tab);
  }, [tab]);
  useEffect(() => {
    // Store the current state in session storage whenever it changes
    sessionStorage.setItem('signupData', JSON.stringify(signupData));
  }, [signupData]);
  console.log('signupData', signupData);
  return (
    <>
      <Header />
      <section className="signup">
        <div className="container custom-container">
          <div className="signup-wrp">
            <div className="form-title">Signup</div>
            <div className="step-wrp">
              <ul className="steps">
                <li
                  className={`step-itm ${
                    Object.keys(signupData.personalInfo).length && 'active'
                  }`}
                >
                  <span className="number">
                    <span className="num">01</span>
                  </span>
                  <span className="title">Personal Info</span>
                </li>
                <li
                  className={`step-itm ${
                    Object.keys(signupData.planDetails).length && 'active'
                  }`}
                >
                  <span className="number">
                    <span className="num">02</span>
                  </span>
                  <span className="title">Choose Plan</span>
                </li>
                <li
                  className={`step-itm ${
                    Object.keys(signupData.payment).length && 'active'
                  }`}
                >
                  <span className="number">
                    <span className="num">03</span>
                  </span>
                  <span className="title">Billing Address &amp; Payment</span>
                </li>
              </ul>
            </div>
            <div className="form-wrp">
              {tab === 'personalInfo' && (
                <PersonalInfo
                  currentTab={tab}
                  switchTab={setTab}
                  setData={setSignupData}
                  data={signupData}
                />
              )}
              {tab === 'choosePlan' && (
                <Plans
                  currentTab={tab}
                  switchTab={setTab}
                  setData={setSignupData}
                  data={signupData}
                  history={history}
                />
              )}
              {tab === 'payment' && (
                <Payment
                  currentTab={tab}
                  switchTab={setTab}
                  setData={setSignupData}
                  data={signupData}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
