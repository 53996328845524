import { Link } from 'react-router-dom';
//import images
import logo from '../../css/images/logo copy.png';
import trophyIcon from '../../css/images/trophy.svg';
import toolsIcon from '../../css/images/tools.svg';
import analyticsIcon from '../../css/images/analytics.svg';
import settingIcon from '../../css/images/setting.svg';
import feedbackIcon from '../../css/images/feedback.svg';
import subscriptionIcon from '../../css/images/subscription.svg';
import logoutIcon from '../../css/images/logout.svg';
//import styles
import '../../css/scss/header.scss';
import '../../css/scss/login.scss';
import '../../css/scss/common-modal.scss';
import '../../css/scss/login-modals.scss';
import '../../css/scss/footer.scss';
import { useEffect, useState } from 'react';
import useLoginReducer from '../Login/reducer/LoginReducer';
import firebase from 'firebase/app';
import history from '../../history';
import subscriptionReducer from '../Subscription/reducer/subscriptionReducer';

export default function Header({ privateMode, togglePrivateMode }) {
  const { logout } = useLoginReducer((state) => state);
  const { profileData } = subscriptionReducer((state) => state);
  const [activeTab, setActiveTab] = useState('');
  const [state, setState] = useState(false);

  const [isExpanded, setIsExpanded] = useState(window.innerWidth <= 992);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 992 && privateMode === true) {
        setIsExpanded(true);
      } else {
        setIsExpanded(false);
      }
    };

    // Check the window size on initial render
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (state && !isExpanded && !privateMode) {
      window.location.reload();
    }
    if (!state && !isExpanded && privateMode === false) {
      window.location.reload();
    }
  }, [state, isExpanded, privateMode]);

  const auth = firebase.auth();

  const handleMenuClick = (tab) => {
    setActiveTab(tab);
    togglePrivateMode(false);
    setIsExpanded(true);
    setState(true);
  };

  const handleFirebaseLogOut = () => {
    auth
      .signOut()
      .then(() => {
        // Sign-out successful.
        localStorage.removeItem('accessToken');
        history.push('/login');
        logout();
      })
      .catch((error) => {
        // An error happened.
      });
  };
  return (
    <header className="header subscription-header">
      <nav className="navbar navbar-expand-lg ">
        <div className="container custom-container">
          <Link className="navbar-brand" to={privateMode ? '/home' : '/'}>
            <img src={logo} alt="" />
          </Link>
          {(privateMode || isExpanded) && (
            <>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
                id="menu-toggle"
                onClick={() => {
                  togglePrivateMode(!privateMode);
                  setIsExpanded(true);
                  setState(false);
                }}>
                <span className />
              </button>
              <div
                className={`collapse navbar-collapse justify-content-center ${
                  !state && (privateMode || isExpanded) ? 'show' : ''
                }`}
                id="navbarNav">
                <ul className="navbar-nav  mx-auto nav-inner">
                  <li
                    className="nav-item  d-flex align-items-center"
                    onClick={() => handleMenuClick('ranges')}>
                    <Link
                      className={`nav-link ${
                        activeTab === 'ranges' && 'active'
                      }`}
                      to="/drd">
                      <span className="ico1">
                        <img src={toolsIcon} alt="Trainer" />
                      </span>
                      <span>Ranges</span>
                    </Link>
                  </li>
                  <li
                    className="nav-item d-flex align-items-center"
                    onClick={() => handleMenuClick('trainer')}>
                    <Link
                      className={`nav-link ${
                        activeTab === 'trainer' && 'active'
                      }`}
                      aria-current="page"
                      to="/trainer">
                      <span className="ico1">
                        <img src={trophyIcon} alt="Trainer" />
                      </span>

                      <span>Trainer</span>
                    </Link>
                  </li>
                  <li
                    className="nav-item  d-flex align-items-center"
                    onClick={() => handleMenuClick('data')}>
                    <Link
                      className={`nav-link ${activeTab === 'data' && 'active'}`}
                      to="/data">
                      <span className="ico1">
                        <img src={analyticsIcon} alt="Trainer" />
                      </span>
                      <span> Data</span>
                    </Link>
                  </li>
                </ul>
              </div>
              <ul
                className="navbar-nav ms-lg-auto settings-nav"
                onClick={() => {
                  if (window.innerWidth <= 992) {
                    togglePrivateMode(!privateMode);
                    setState(true);
                  }
                }}>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link settings-ico"
                    // href="#"
                    id="settingsDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false">
                    <img src={settingIcon} alt="Settings" />
                  </a>
                  <ul
                    className="dropdown-menu dropdown-menu-end settings-items"
                    aria-labelledby="settingsDropdown">
                    {/* <li className="nav-item  d-flex align-items-center">
                      <Link
                        to="/trees"
                        className="nav-item"
                        onClick={() => setActiveTab('')}>
                        <span className="ico2">
                          <img src={feedbackIcon} alt="Trainer" />
                        </span>
                        <span>Trees</span>
                      </Link>
                    </li> */}

                    <li className="nav-item  d-flex align-items-center">
                      <Link
                        to="/tools"
                        className="nav-item"
                        onClick={() => setActiveTab('')}>
                        <span className="ico2">
                          <img src={toolsIcon} alt="Trainer" />
                        </span>
                        <span>Tools</span>
                      </Link>
                    </li>

                    <li className="nav-item  d-flex align-items-center">
                      <Link
                        to="/settings"
                        className="nav-item"
                        onClick={() => setActiveTab('')}>
                        <span className="ico2">
                          <img src={settingIcon} alt="Trainer" className />
                        </span>
                        <span>Settings</span>
                      </Link>
                    </li>

                    <li className="nav-item  d-flex align-items-center">
                      <Link
                        to="/feedback"
                        className="nav-item"
                        onClick={() => setActiveTab('')}>
                        <span className="ico2">
                          <img src={feedbackIcon} alt="Trainer" />
                        </span>
                        <span>Feedback</span>
                      </Link>
                    </li>
                    {!profileData.isVip && (
                      <li className="nav-item  d-flex align-items-center">
                        <Link
                          className="nav-item"
                          to="/subscription"
                          onClick={() => setActiveTab('')}>
                          <span className="ico2">
                            <img src={subscriptionIcon} alt="Trainer" />
                          </span>
                          <span>Subscription</span>
                        </Link>
                      </li>
                    )}

                    {/* <li className="nav-item  d-flex align-items-center">
                      <span>
                        <img src={subscriptionIcon} alt="Trainer" />
                      </span>
                      <span>
                        <Link className="nav-item" to="/upload">
                          Upload Tree
                        </Link>
                      </span>
                    </li> */}

                    <li className="nav-item  d-flex align-items-center">
                      <Link
                        to="/"
                        className="nav-item"
                        onClick={() => handleFirebaseLogOut()}>
                        <span className="ico2">
                          <img src={logoutIcon} alt="Trainer" />
                        </span>
                        <span>Logout</span>
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </>
          )}
        </div>
      </nav>
    </header>
  );
}
